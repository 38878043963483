import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { isEmpty } from "components/utils";
import xstyles from "assets/jss/material-kit-react/views/touhuSivu.js";
import { makeStyles } from "@material-ui/core/styles";
//import SeuraInfo from "components/SeuraInfo/SeuraInfo.js";
//import Seurakuva from "components/Seurakuva/Seurakuva.js";
//<Seurakuva seuranimi={name} seuraid={aid} />

const xuseStyles = makeStyles(xstyles);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(true);
  //const [seuraOpen, setSeuraOpen] = React.useState(false);
  const classes = xuseStyles();

  /*  const handleClickOpen = () => {
    setOpen(true);
  };*/
  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };
  ////console.log("halliTieto: ", props.halliTieto);
  var obje = {};
  var seurat = [];
  obje = props.halliTieto;
  seurat = props.hallinSeurat;
  //console.log("Seurat ", seurat);
  /*  if (!isEmpty(props.halliTieto)) {
    var propes = obje.getProperties();
    //console.log("halliTieto  len: " + propes.length);
    //console.log("halliTieto namefi: ", propes["name_fi"]);
  }
  */
  ////console.log("halliTietolength: " + props.halliTieto.name.length);
  //if (!isEmpty(props.halliTieto)) {
  if (!isEmpty(obje)) {
    var propes = obje.properties;
    // fetch start

    if (!open) {
      setOpen(true);
    }
    //console.log("seuratieto len: " + seurat.length);
    var hasSeuroja = seurat.length > 0;
    return (
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {propes["name"]}
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>Hallin nimi: {propes["name"]}</Typography>
            <Typography gutterBottom>{propes["address"]}</Typography>
            <Typography gutterBottom>{propes["zip"]}</Typography>
            <Typography gutterBottom>{propes["city"]}</Typography>
            <Typography gutterBottom>{propes["name"]}</Typography>
            {propes["www"].length > 0 && (
              <div className={classes.breadText}>
                <a
                  href={propes["www"]}
                  rel="noreferrer"
                  className={classes.block}
                  target="_blank"
                >
                  {propes["www"]}
                </a>
              </div>
            )}
            <div className={classes.overFlow}>
              <h4 className={classes.titleH4_FC}>
                Hallissa toimivat seuraavat seurat{" "}
              </h4>
              <Typography gutterBottom>
                Hallissa toimivat seuraavat seurat{" "}
              </Typography>
              {hasSeuroja ? (
                <div>
                  {seurat.map(({ name, aid }, index) => (
                    <span key={index}>
                      <div className={classes.breadText}>
                        <a
                          href={
                            "http://www.leijonat.fi/index.php/seurat?aid=" + aid
                          }
                          rel="noreferrer"
                          className={classes.block}
                          target="_blank"
                        >
                          {name}
                        </a>
                      </div>
                    </span>
                  ))}
                </div>
              ) : (
                <div>Tässä hallissa ei operoi mikään seura</div>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              Sulje
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    //    setOpen(false);
    if (open) {
      setOpen(false);
    }
    //    //console.log("halliTieto tyhjä ");
    return <div hidden>tyhja</div>;
  }
}
CustomizedDialogs.propTypes = {
  //title: PropTypes.string.isRequired,
  halliTieto: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  hallinSeurat: PropTypes.array,
};

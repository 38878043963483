import axios from "axios";
// the Knuth shuffle algorithm
export function ajaxHandler(item) {
  //const CancelToken = axios.CancelToken;
  //const ajaxSource = CancelToken.source();

  this.cAjax = item;
  //console.log('Ajax: constructor: '+this.cAjax);
  this.stop = function () {
    if (this.cAjax !== null) {
      console.log("Ajax: to be cancelled: " + this.cAjax);

      this.ajaxsource.cancel();
      this.cAjax = null;
      this.canceltoken = null;
      this.ajaxsource = null;
      //    console.log('Ajax: cancelled: '+this.cAjax);
    }
  };
  this.set = function () {
    //console.log('Ajax: to be set: '+this.cAjax);
    //sleep(500);
    this.cAjax = 1;
    this.canceltoken = axios.CancelToken;
    this.ajaxsource = this.canceltoken.source();

    //  console.log('Ajax: has been set: '+this.cAjax);
  };
  this.unset = function () {
    //console.log('Ajax: to be UNset: '+this.cAjax);
    this.cAjax = null;
    this.canceltoken = null;
    this.ajaxsource = null;
    //console.log('Ajax: has been UNset: '+this.cAjax);
  };
  this.get = function () {
    //console.log('Ajax: to be get: '+this.cAjax);
    return this.cAjax;
  };
  this.getsource = function () {
    //console.log('Ajax: to be get: '+this.cAjax);
    return this.ajaxsource.token;
  };
}
export function sleep(milliseconds) {
  var start = new Date().getTime();
  for (var i = 0; i < 1e7; i++) {
    if (new Date().getTime() - start > milliseconds) {
      break;
    }
  }
}

export function radio01_text2index(text) {
  if (text == "type") {
    return 0;
  } else if (text == "format") {
    return 1;
  } else {
    return 2;
  }
}
export function isEmpty(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      //console.log("geofence stringy fidu" + JSON.stringify(obj));
      return false;
    }
  }
  //console.log("geofence stringy " + JSON.stringify(obj));
  //console.log("geofence stringy " + JSON.stringify({}));
  return JSON.stringify(obj) === JSON.stringify({});
}

import * as React from "react";

import MapComponent from "@terrestris/react-geo/dist/Map/MapComponent/MapComponent";
import "ol/ol.css";
import "antd/dist/antd.css";
// ei toimi p..le import MapComponent from "@terrestris/react-geo";
//import LayerTree from '@terrestris/react-geo/LayerTree/LayerTree';
//import LayerTree from "@terrestris/react-geo/dist/LayerTree/LayerTree";
//import MapContext from '@terrestris/react-geo/Context/MapContext/MapContext';
//import MapContext from "@terrestris/react-geo/dist/Context/MapContext/MapContext";
//import { useMap } from '@terrestris/react-geo/Hook/useMap';
import { useMap } from "@terrestris/react-geo/dist/Hook/useMap";
import PropTypes from "prop-types";
var lastFeature = null;
/*import axios from "axios";
import { ajaxHandler } from "components/utils";
*/
/*
const { Client } = require("pg");
const client = new Client();
*/
/*
import OlMap from "ol/Map";
import OlView from "ol/View";
import OlLayerTile from "ol/layer/Tile";
import OlSourceOsm from "ol/source/OSM";

const layer = new OlLayerTile({
  source: new OlSourceOsm(),
  name: "OSM",
});const openlayersMap = new OlMap({
  target: null,
  view: new OlView({
    center: [135.1691495, 34.6565482],
    projection: "EPSG:4326",
    zoom: 16,
  }),
  layers: [layer],
});
*/
//function ComponentToUseTheMap() {
export default function Kartta(props) {
  const map = useMap();
  var view = map.getView();
  if (props.mapCenter) {
    //console.log("geometry: " + props.mapCenter[0]);
    view.animate({ center: [props.mapCenter[0], props.mapCenter[1]] });
  }
  ////console.log("client o%", client);
  //map.on("singleclick", function (evt) {
  map.on("click", function (evt) {
    // stop similar evt
    evt.stopPropagation();
    //console.log("map click", evt);
    //var coord3857 = evt.coordinate;
    //console.log("pointer coordinates:" + coord3857);
    //document.getElementById("info").innerHTML = "";
    const viewResolution = /** @type {number} */ (view.getResolution());
    var wmsSource = props.wmsSource;
    //console.log("wmssource", wmsSource);
    const url = wmsSource.getFeatureInfoUrl(
      evt.coordinate,
      viewResolution,
      "EPSG:3857",
      { INFO_FORMAT: "application/json" }
      //{ INFO_FORMAT: "text/html" }
    );
    if (url) {
      fetch(url)
        .then((response) => response.text())
        .then((html) => {
          //console.log("features htmlö: ", JSON.parse(html));
          var featurecollection = JSON.parse(html);
          //console.log("features html.len " + featurecollection.features.length);
          if (featurecollection.features.length > 0) {
            var feature = featurecollection.features[0];
            //var featureProperties = feature.properties;
            //console.log("features0: ", featurecollection.features[0]);
            //console.log("featuresprop: ", featureProperties);
            //document.getElementById("info").innerHTML =
            //  featureProperties["name"];
            props.onClick(feature);
          }
        });
    }

    /*if (lastFeature) {
    //console.log("feature aingleclick last: ", lastFeature);
  } else {
  */
    //alert("widu");
    //console.log("feature aingleclick click: ", lastFeature);
    lastFeature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
      return feature;
    });
    //console.log("feature aingleclick click: ", lastFeature);
    if (lastFeature) {
      var lastidtxt = lastFeature.getId();
      //console.log("feature aingleclick clicktxt: ", lastidtxt);
      var arra = lastidtxt.split(".");
      //console.log("feature aingleclick clickarra: ", arra);
      if (arra.length > 1) {
        //var jid = arra[1];
        //props.onClick(jid);
        props.onClick(lastFeature);
      }
    }
  });
  map.once("moveend", function () {
    //console.log("evt: o%", evt);
    //var currentZoom = parseInt(view.getZoom(), 10);
    //console.log("once move end:" + currentZoom);
    //var extend = view.calculateExtent(map.getSize());
    //console.log("once move end:" + extend);
  });
  map.on("moveend", function (evt) {
    // stop similar evt
    evt.stopPropagation();
    //console.log("evt: o%", evt);
    //var currentZoom = parseInt(view.getZoom(), 10);
    //console.log("move end:" + currentZoom);
    var extend = view.calculateExtent(map.getSize());
    //console.log("move end:" + extend);
    props.viewChange(extend);
    //props.mapCenter = [10, 10];
  });
  /*map.on("pointermove", function (evt) {
    //buildi20211007 consolelogobj("pointermove evt: o%", evt);
    //buildi20211007 var currentZoom = parseInt(view.getZoom(), 10);
    //buildi20211007 consolelogobj("pointermove:" + currentZoom);
    //var extend = view.calculateExtent(map.getSize());
    ////console.log("pointermove:");
    //buildi20211007 var coord3857 = evt.coordinate;
    //buildi20211007 consolelogtxt("pointermove coordinates:" + coord3857);
    //var hit = false;
    const feature = map.forEachFeatureAtPixel(
      evt.pixel,
      function (feature, layer) {
        //hit = true;
        //console.log("layer: ", layer);
        return feature;
      },
      {
        hitTolerance: 10,
      }
    );
    ////console.log("hitti: " + hit);
    if (feature) {
      //console.log("featureatpixel: o%", feature);
    }

    //    props.viewChange(extend);
    //props.mapCenter = [10, 10];
  });
*/
  // This is example specific and usually not needed
  React.useEffect(() => {
    //console.log("at useEffect kartta");
    map.setTarget("usemap-map");
  }, []);

  return (
    <MapComponent
      id={"usemap-map"}
      map={map}
      style={{
        height: "400px",
      }}
    />
  );
}
Kartta.propTypes = {
  viewChange: PropTypes.func,
  onClick: PropTypes.func,
  mapCenter: PropTypes.array,
  wmsSource: PropTypes.object,
};
/*
function LayerTreeToUseTheMap() {
  const map = useMap();

  return <LayerTree map={map} />;
}*/
/*
function UseMapExample() {
  return(
    <MapContext.Provider value={openlayersMap}>
      <ComponentToUseTheMap />
      <LayerTreeToUseTheMap />
    </MapContext.Provider>
  );
}

<UseMapExample />
*/

import React from "react";
import PropTypes from "prop-types";
//import Button from "components/CustomButtons/Button.js";
//import Card from "components/Card/Card.js";
//import CardBody from "components/Card/CardBody.js";
//import CardHeader from "components/Card/CardHeader.js";
import styles from "assets/jss/material-kit-react/views/touhuSivu.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function SeuraInfoFC(props) {
  //const { ...rest } = props;
  const classes = useStyles();
  const { seuraFCdata } = props;
  console.log("aidda: ", props.seuraFCdata);
  console.log("aidda: ", seuraFCdata);
  return (
    <div>
      <h4 className={classes.titleH4_FC}>
        Seura tuottaa seuraavia harrastusmahdollisuuksia{" "}
      </h4>
      <div>
        {seuraFCdata.map(
          ({ classdescription_fi, readmorelink_fi, classname_fi }, index) => (
            <span key={index}>
              <h5 className={classes.titleH4}>{classname_fi} </h5>
              <div className={classes.breadText}>{classdescription_fi} </div>
              {readmorelink_fi.length > 0 && (
                <div className={classes.breadText}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={readmorelink_fi}
                  >
                    Lisätietoja tästä klikkaamalla
                  </a>
                </div>
              )}
            </span>
          )
        )}
      </div>
    </div>
  );
}
SeuraInfoFC.propTypes = {
  seuraFCdata: PropTypes.array,
};

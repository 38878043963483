import React from "react";
import ReactDOM from "react-dom";
//import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.10.0";

// pages for this product
import Media from "react-media"; // add Media
//import Components from "views/Components/Components.js";
//import LandingPage from "views/LandingPage/LandingPage.js";
import LaskeutumisSivu from "views/LandingPage/LaskeutumisSivu.js";
import TouhuSivu from "views/TouhuSivu/TouhuSivu.js";
//import TouhuSivu from "TouhuSivu.js";
//import ProfilePage from "views/ProfilePage/ProfilePage.js";
//import LoginPage from "views/LoginPage/LoginPage.js";
import history from "./history";
//var hist = createBrowserHistory();
//<Router history={hist}>
ReactDOM.render(
  <Router basename="/" history={history}>
    <Media query="(max-width: 599px)">
      {(matches) =>
        matches ? (
          <Switch>
            <Route path="/haku" component={TouhuSivu} />
            <Route path="/" component={TouhuSivu} />
          </Switch>
        ) : (
          <Switch>
            <Route path="/haku" component={TouhuSivu} />
            <Route path="/" component={LaskeutumisSivu} />
          </Switch>
        )
      }
    </Media>
  </Router>,
  document.getElementById("root")
);

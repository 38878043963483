import React from "react";
import PropTypes from "prop-types";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

export default function Seurakuva(props) {
  //const { ...rest } = props;
  const { seuraid, slickClick, seuranimi } = props;
  var image =
    "https://tulospalvelu.leijonat.fi/images/associations/weblogos/200x200/" +
    seuraid +
    ".png";
  return (
    <Card>
      <CardHeader>{seuranimi}</CardHeader>
      <CardBody>
        <div
          id="seuralogokuva"
          title={seuranimi}
          onClick={() => slickClick(seuraid, seuranimi)}
        >
          <div>
            <img
              style={{ maxHeight: "287px", maxWidth: "287px", xheight: "auto" }}
              src={image}
              alt={seuranimi + "(Logo puuttuu)"}
              className="slick-image"
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
Seurakuva.propTypes = {
  seuraid: PropTypes.string,
  seuranimi: PropTypes.string,
  slickClick: PropTypes.func,
};

// * hallien aagregointi
// * positron kartta
// * etsiseura.fi
//
//https://www.finhockey.fi/images/logot/Logo100x81.png
//https://www.finhockey.fi/images/logo/intohimona_jaakiekko_2020.jpg
//https://www.finhockey.fi/images/logo/intohimona_jaakiekko_2020.jpg
//https://www.finhockey.fi/images/home.png
import React, { useState } from "react";
// nodejs library that concatenates classes
//import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";
import Button from "@material-ui/core/Button";
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
//import Parallax from "components/Parallax/Parallax.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Badge from "components/Badge/Badge.js";
import styles from "assets/jss/material-kit-react/views/touhuSivu.js";
import Karuselli from "components/Karuselli/Karuselli.js";
//import CustomInput from "components/CustomInput/CustomInput.js";
import Kartta from "components/Kartta/Kartta.js";
//import Modal from "components/Modal/Modal.js";
import MapContext from "@terrestris/react-geo/dist/Context/MapContext/MapContext";
import OlMap from "ol/Map";
import OlView from "ol/View";
import OlLayerTile from "ol/layer/Tile";
import OlSourceOsm from "ol/source/OSM";
import ImageLayer from "ol/layer/Image";
import ImageWMS from "ol/source/ImageWMS.js";
import axios from "axios";
import { ajaxHandler } from "components/utils";

import NavInput from "components/NavInput/NavInput.js";
import SeuraInfo from "components/SeuraInfo/SeuraInfo.js";
import Modal from "components/Modal/SJLModal.js";
import { isEmpty } from "components/utils";
// Sections for this page
/*import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import WorkSection from "./Sections/WorkSection.js";
*/
//var geoserverurl = "https://www.reformaattori.fi/geoserver/wms";
//var apiBaseUrl = window.location.href;
var apiBaseUrl = window.location.origin+"/";
//process.env.REACT_APP_APIBASEURL;
var geoserverurl = process.env.REACT_APP_GEOSERVERURL;

var showSeuraHeat = -1;
var noBadgeSelected = true;
var lastExtent = [0, 0, 0, 0];
var seuraImage;
var seuraIndex;
var seuraTieto = [];
var seuraFCdata = [];
var hallinSeurat = [];
const dashboardRoutes = [];
var FCdata = [];
//https://www.facebook.com/pages/Suomen-J%C3%A4%C3%A4kiekkoliitto/794817883948254
//https://www.youtube.com/watch?v=e8Ok89Hu-Bg
const useStyles = makeStyles(styles);
//const myStyles = makeStyles();

//kuva: https://unsplash.com/s/photos/ice-rink
//require("assets/img/chris-liverani-5oZ9uVx7buc-unsplash.jpg").default
//require("assets/img/hockey-rink.svg").default

//ikonit: https://mapicons.mapsmarker.com/
const layer = new OlLayerTile({
  source: new OlSourceOsm(),
  name: "OSM",
});
const jaahalliSource = new ImageWMS({
  url: geoserverurl,
  params: {
    LAYERS: "sjletsiseura_jaahallit",
    //LAYERS: "kohteet_filter",
    //      minZoom: 4,
    //      maxZoom: 8,
  },
  ratio: 1,
  opacity: 0.5,
  serverType: "geoserver",
});
const jaahalliLayer = new ImageLayer({
  radnomi: Math.random() * 10000000,
  name: "Jaahallit",
  //redraw: true,
  crossOrigin: "anonymous",
  source: jaahalliSource,
});
const jaahalliKatLayer = new ImageLayer({
  radnomi: Math.random() * 10000000,
  name: "Jaahallit_kat",
  //redraw: true,
  crossOrigin: "anonymous",
  source: new ImageWMS({
    url: geoserverurl,
    params: {
      LAYERS: "sjletsiseura_fc_kategoria_hallit",
      //      minZoom: 4,
      //      maxZoom: 8,
    },
    ratio: 1,
    opacity: 0.5,
    serverType: "geoserver",
  }),
});
//seuralisatiedot_suunnat_json
/* 9.12.2091 poistetaan ainakin toistaiseksi
const jaahalliSuunnatLayer = new ImageLayer({
  radnomi: Math.random() * 10000000,
  name: "Jaahallit_suunnat",
  //redraw: true,
  crossOrigin: "anonymous",
  source: new ImageWMS({
    url: geoserverurl,
    params: {
      LAYERS: "seuralisatiedot_suunnat_json",
      CQL_FILTER: "aid IN (999999999)",
      //      minZoom: 4,
      //      maxZoom: 8,
    },
    ratio: 1,
    opacity: 0.5,
    serverType: "geoserver",
  }),
});
*/
//heat layer
const heatMapLayer = new ImageLayer({
  radnomi: Math.random() * 10000000,
  name: "sjl_etsiseura_heatdata",
  //redraw: true,
  crossOrigin: "anonymous",
  source: new ImageWMS({
    url: geoserverurl,
    params: {
      LAYERS: "	sjl_heatmap_lg",
      CQL_FILTER: "aid IN (999999999)",
      //      minZoom: 4,
      //      maxZoom: 8,
    },
    ratio: 1,
    opacity: 0.5,
    serverType: "geoserver",
  }),
});

const openlayersMap = new OlMap({
  target: null,
  view: new OlView({
    center: [2775425, 8444163],
    projection: "EPSG:3857",
    zoom: 12,
    minZoom: 5,
    maxZoom: 14,
  }),
  layers: [layer, heatMapLayer, jaahalliKatLayer, jaahalliLayer],
  //layers: [layer, jaahalliSuunnatLayer, jaahalliKatLayer, jaahalliLayer],
  //layers: [jaahalliLayer],
});
const cAjaxRetu = new ajaxHandler(null);
const cAjaxRetu_fc = new ajaxHandler(null);
const cAjaxSeuraFc = new ajaxHandler(null);
const cAjaxSeuraByRid = new ajaxHandler(null);
const navinputLabel = "Hae paikkaa";

export default function TouhuSivu(props) {
  /*//console.log("layer: " + layer);
  //console.log("layer: " + jaahalliLayer);
  //console.log("fc: " + FCdata);
  */
  function navChange(value) {
    if (value) {
      //console.log("nav value : " + value.name);
      //console.log("nav geom : " + value.geometry);
      //console.log("nav geom -X : " + value.x);
      //console.log("nav geom -Y: " + value.y);
      var center = [];
      center[0] = value.x;
      center[1] = value.y;
      setMapCenter(center);
    }
  }
  function nollaaClick() {
    var myArray = [];
    noBadgeSelected = true; //=navi näytetään
    // luupataan ja merkataan jokainen state epävalituksi
    myArray = FCdata.map(function (a) {
      a.state = 1;
      a.color = "success";
      return a;
    });
    FCdata = myArray;
    //setFCdata(myArray);
    //ei toimi setFCdata(FCdata);
    //viewChange(lastExtent);
    setMapCenter(null);
    returnData(lastExtent);
  }
  function badgeClick(event) {
    //console.log("badgeclick: o% ", event);
    var target = event.target;
    //console.log("badgeclick: o% ", target);
    //console.log("badgeclick: o% ", target.id);
    //console.log("badgeclick state: o% ", FCdata[0]);
    var found = FCdata.findIndex((element) => element.classid === target.id);
    //console.log("LoytyIko: " + found);
    if (found > -1) {
      //console.log(
      // "LÖYTYI " + FCdata.indexOf((element) => element.classid === target.id)
      //);
      //console.log("state: " + FCdata[found].state);
      //      FCdata[found].state = 0;
      //console.log("state: " + FCdata[found].state);
      var myArray = [];
      // toggletaan status klikatulle
      noBadgeSelected = true; //=true yhtään badgea ei valittuna jolloin navia ei näytetä
      myArray = FCdata.map(function (a) {
        if (a.classid == target.id) {
          a.state = a.state + 1;
          a.state = a.state % 2;
          if (a.state == 0) {
            a.color = "primary";
          } else {
            a.color = "success";
          }
        }

        if (a.state == 0) {
          noBadgeSelected = false; //=false ainakin yksi badge on valittuna
        }
        return a;
      });
      FCdata = myArray;
      //setFCdata(myArray);
      //ei toimi setFCdata(FCdata);
      //viewChange(lastExtent);
      setMapCenter(null);
      returnData(lastExtent);
    }
  }
  function viewChange(extent) {
    //console.log("new  extent at viewchange: " + extent);
    //console.log("last extent at viewchange: " + lastExtent);
    if (
      //!lastExtent ||
      //      lastExtent.length > 0 &&
      lastExtent[0] !== extent[0] ||
      lastExtent[1] !== extent[1] ||
      lastExtent[2] !== extent[2] ||
      lastExtent[3] !== extent[3]
    ) {
      //console.log("lastextent at viewchange gonna get data", lastExtent);
      lastExtent = extent;
      setMapCenter(null);
      returnData(extent);
    } else {
      //console.log("passed extent at viewchange: " + extent);
    }
  }
  function modalOnClose() {
    setHalliObject({});
  }

  async function karttaClick(feature) {
    //console.log("feature at karttaclick: ", feature);
    var featureProperties = feature.properties;
    //console.log("featureproperties at karttaclick: ", featureProperties);
    var payload = {
      rid: featureProperties["rid"],
    };
    cAjaxSeuraByRid.stop();
    cAjaxSeuraByRid.set();
    const ressu = await axios.get(
      apiBaseUrl + "includes/getSeuraDataByRid.php",
      {
        params: payload,
        timeout: 60 * 4 * 1000,
        cancelToken: cAjaxSeuraByRid.getsource(),
      }
    );
    const data = await ressu;
    cAjaxSeuraByRid.unset();
    hallinSeurat = data.data;
    //console.log("hallinSeuratlen:" + hallinSeurat.length);
    //console.log("ahallinSeuratdata:", hallinSeurat);

    setHalliObject(feature);
  }
  async function karuselliClick(aid) {
    //console.log("aid at karuselliclick: " + aid);
    //get seura fc
    var payload = {
      aid: aid,
    };
    cAjaxSeuraFc.stop();
    cAjaxSeuraFc.set();
    const ressu = await axios.get(apiBaseUrl + "includes/getFcDataByAid.php", {
      params: payload,
      timeout: 60 * 4 * 1000,
      cancelToken: cAjaxSeuraFc.getsource(),
    });
    const data = await ressu;
    cAjaxSeuraFc.unset();
    seuraFCdata = data.data;
    //console.log("aiddatalen:" + seuraFCdata.length);
    //console.log("aiddata:", seuraFCdata);
    seuraImage =
      "https://tulospalvelu.leijonat.fi/images/associations/weblogos/200x200/" +
      aid +
      ".png";
    //console.log("seuraimage: " + seuraImage);
    seuraIndex = geedata.findIndex((element) => element.aid === aid);
    //console.log("Found: " + seuraIndex);
    seuraTieto = geedata[seuraIndex];
    /* 9.12.2091 poistetaan ainakin toistaiseksi
    var params_suunta = jaahalliSuunnatLayer.getSource().getParams();
    params_suunta.CQL_FILTER = "aid=" + aid;
    jaahalliSuunnatLayer.getSource().updateParams(params_suunta);
    openlayersMap.removeLayer(jaahalliSuunnatLayer);
    openlayersMap.addLayer(jaahalliSuunnatLayer);
    */
    var params_suunta = heatMapLayer.getSource().getParams();
    params_suunta.CQL_FILTER = "aid=" + aid;
    heatMapLayer.getSource().updateParams(params_suunta);
    //console.log("remove heatmap");
    openlayersMap.removeLayer(heatMapLayer);
    openlayersMap.addLayer(heatMapLayer);
    showSeuraHeat = aid;
    setSeuravisible(aid);
  }
  function seuraClose() {
    //console.log("close seura");
    /*var aidArray = [];
    aidArray = geedata.map(function (a) {
      //console.log("a.aid :" + a.aid);
      return a.aid;
    });*/
    /* 9.12.2091 poistetaan ainakin toistaiseksi
    var params_suunta = jaahalliSuunnatLayer.getSource().getParams();
    //params_suunta.CQL_FILTER = "aid IN (" + aidArray.toString() + ")";
    // halutaan suunnat vain jos seura valittuna
    params_suunta.CQL_FILTER = "aid IN (999999999)";
    jaahalliSuunnatLayer.getSource().updateParams(params_suunta);
    openlayersMap.removeLayer(jaahalliSuunnatLayer);
    openlayersMap.addLayer(jaahalliSuunnatLayer);
    */
    var params_suunta = heatMapLayer.getSource().getParams();
    //params_suunta.CQL_FILTER = "aid IN (" + aidArray.toString() + ")";
    // halutaan suunnat vain jos seura valittuna
    params_suunta.CQL_FILTER = "aid IN (999999999)";
    heatMapLayer.getSource().updateParams(params_suunta);
    //console.log("remove heatmap");
    openlayersMap.removeLayer(heatMapLayer);
    openlayersMap.addLayer(heatMapLayer);
    showSeuraHeat = -1;
    setSeuravisible(0);
  }

  async function returnData(extent) {
    //console.log("'returnData");
    //var params = layerVector.getSource().getParams();
    //var params = jaahalliKatLayer.getSource.getParams();

    var payload = {
      extent: extent,
      fcdata: FCdata,
    };

    cAjaxRetu.stop();
    cAjaxRetu.set();
    const res = await axios.get(apiBaseUrl + "includes/returnData.php", {
      params: payload,
      timeout: 60 * 4 * 1000,
      cancelToken: cAjaxRetu.getsource(),
    });
    const data = await res;
    cAjaxRetu.unset();
    ////console.log("gdata: o%", data.data[0]);
    ////console.log("gdata:" + data.data[0]["name"]);
    ////console.log("gdata-aid:" + data.data[0]["aid"]);
    //console.log("gdatalen:" + data.data.length);
    var daata = data.data;
    var params = jaahalliKatLayer.getSource().getParams();
    /* 9.12.2091 poistetaan ainakin toistaiseksi
    var params_suunta = jaahalliSuunnatLayer.getSource().getParams();
    */
    var params_suunta = heatMapLayer.getSource().getParams();
    //console.log("params: o%", params);
    if (data.data[0] == "<" || data.data.length == 0) {
      daata = [];
      if (showSeuraHeat == -1) {
        //console.log("heatonko  " + seuravisible);
        /* 9.12.2091 poistetaan ainakin toistaiseksi
        params_suunta.CQL_FILTER = "aid IN (999999999)";
        jaahalliSuunnatLayer.getSource().updateParams(params_suunta);
        openlayersMap.removeLayer(jaahalliSuunnatLayer);
        openlayersMap.addLayer(jaahalliSuunnatLayer);
        */
        params_suunta.CQL_FILTER = "aid IN (999999999)";
        heatMapLayer.getSource().updateParams(params_suunta);
        //console.log("remove heatmap");
        openlayersMap.removeLayer(heatMapLayer);
        openlayersMap.addLayer(heatMapLayer);
      }

      params.CQL_FILTER = "aid IN (999999999)";
      jaahalliKatLayer.getSource().updateParams(params);
      openlayersMap.removeLayer(jaahalliKatLayer);
      openlayersMap.addLayer(jaahalliKatLayer);
    } else {
      var aidArray = [];
      aidArray = daata.map(function (a) {
        //console.log("a.aid :" + a.aid);
        return a.aid;
      });
      //params_suunta.CQL_FILTER = "aid IN (" + aidArray.toString() + ")";
      // halutaan suunnat vain jos seura valittuna
      /*params_suunta.CQL_FILTER = "aid IN (999999999)";
      jaahalliSuunnatLayer.getSource().updateParams(params_suunta);
      openlayersMap.removeLayer(jaahalliSuunnatLayer);
      openlayersMap.addLayer(jaahalliSuunnatLayer);
      */

      params.CQL_FILTER = "aid IN (" + aidArray.toString() + ")";
      jaahalliKatLayer.getSource().updateParams(params);
      openlayersMap.removeLayer(jaahalliKatLayer);
      openlayersMap.addLayer(jaahalliKatLayer);
    }
    setGeedata(daata);
    /*cAjaxRetu_fc.stop();
    cAjaxRetu_fc.set();
    var extentall = [];
    extentall[0] = 0;
    extentall[1] = 0;
    extentall[2] = 9999999;
    extentall[3] = 9999999;

    const res_fc = await axios.get(apiBaseUrl + "includes/getFcData.php", {
      timeout: 60 * 4 * 1000,
      cancelToken: cAjaxRetu_fc.getsource(),
    });
    const data_fc = await res_fc;
    cAjaxRetu_fc.unset();
    ////console.log("gdata:" + data_fc.data[0].ClassName_FI);
    ////console.log("gdatalen:" + data_fc.data.length);
    var daata_fc = data_fc.data;
    if (data_fc.data[0] == "<" || data_fc.data.length == 0) {
      daata_fc = [];
    }
    //console.log("daata_fc: o%", daata_fc);
    //console.log("fcdata: o%", FCdata);
    var myArray = [];
    myArray = daata_fc.map(function (a) {
      var found = FCdata.findIndex(
        (element) => element.classid * 1 == a.classid * 1
      );
      //console.log(a.classid + " -->LoytyIko: " + found);
      if (found > -1) {
        //console.log("LÖYTYI " + found);
        //console.log("LÖYTYIstate " + FCdata[found].state);
        a.state = FCdata[found].state;
      } else {
        a.state = 1;
      }
      if (a.state == 0) {
        a.color = "primary";
      } else {
        a.color = "success";
      }
      return a;
    });
    //console.log("myarraay: ", myArray);
    setFCdata(myArray);*/
  }
  const [halliObject, setHalliObject] = useState({});
  const [geedata, setGeedata] = useState([]);
  //const [FCdata, setFCdata] = useState([]);
  const [mapCenter, setMapCenter] = useState([2775425, 8444163]);
  const [seuravisible, setSeuravisible] = useState(0);
  const classes = useStyles();
  //const myclasses = myStyles();
  const myUseStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    karttaCard: {
      marginTop: 1,
      marginBottom: 1,
      minHeight: 350,
      minWidth: 350,
      maxWidth: 750,
      //padding: theme.spacing(2),
      paddingLeft: 1,
      paddingRight: 1,
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    parallaxstyle: {
      minHeight: 30,
      maxHeight: 350,
      maxWidth: 650,
    },
    badgeCard: {
      marginTop: 15,
      marginBottom: 15,
      minHeight: 50,
      minWidth: 350,
      //maxWidth: 750,
      padding: theme.spacing(0),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  }));
  const myclasses = myUseStyles();

  const { ...rest } = props;
  async function getFCdata() {
    cAjaxRetu_fc.stop();
    cAjaxRetu_fc.set();
    const res_fc = await axios.get(apiBaseUrl + "includes/getFcData.php", {
      timeout: 60 * 4 * 1000,
      cancelToken: cAjaxRetu_fc.getsource(),
    });
    const data_fc = await res_fc;
    var daata_fc = data_fc.data;
    cAjaxRetu_fc.unset();
    var myArray = [];
    myArray = daata_fc.map(function (a) {
      a.state = 1;
      a.color = "success";
      return a;
    });
    //console.log("myarraay: ", myArray);
    FCdata = myArray;
    //setFCdata(myArray);
  }
  React.useEffect(() => {
    //console.log("at useEffect touhusivu");
    getFCdata();
  }, []);
  return (
    <div
      id="bgidx"
      className={classes.container}
      style={{ backgroundImage: "url(img/AMJv.jpg)" }}
    >
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Header
              color="primary"
              routes={dashboardRoutes}
              brand="Etsiseura"
              rightLinks={<HeaderLinks />}
              fixed
              changeColorOnScroll={{
                height: 800,
                color: "info",
              }}
              {...rest}
            />
          </GridItem>
        </GridContainer>
      </div>
      <div className={classes.container}>
        <h6 className={classes.title}></h6>
        <br />
        <div hidden={isEmpty(halliObject)}>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            halliTieto={halliObject}
            hallinSeurat={hallinSeurat}
            onClose={modalOnClose}
          />
        </div>
        <div className={myclasses.karttaxdiv}>
          {noBadgeSelected && (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card className={myclasses.badgeCard}>
                  <CardBody>
                    <NavInput
                      label={navinputLabel}
                      urli={apiBaseUrl + "includes/getNaviData.php"}
                      onChange={navChange}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          )}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card className={myclasses.badgeCard}>
                <CardBody>
                  {geedata.length < 1 && (
                    <div title="Valintasi vaikuttaa siihen mitä seuroja on karusellissa ">
                      Alla on lueteltu mitä harrastusmahdollisuuksia seurat
                      tarjoavat. Klikkaa jos olet kiinnostunut, niin saat
                      kartalle tietoa niistä halleista, joissa kyseinen
                      harrastusmahdollisuus on tarjolla{" "}
                    </div>
                  )}
                  <div onClick={badgeClick} style={{ cursor: "pointer" }}>
                    {FCdata.map(
                      ({ classname_fi, classid, color, visible }, index) => (
                        <span key={index}>
                          {visible > 0 && (
                            <Badge color={color} key={index} index={index}>
                              <span id={classid} key={index}>
                                {classname_fi}
                              </span>
                            </Badge>
                          )}
                        </span>
                      )
                    )}
                  </div>
                  {!noBadgeSelected && (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <div>
                          <Button onClick={nollaaClick} color="primary">
                            Nollaa valinnat
                          </Button>
                        </div>
                      </GridItem>
                    </GridContainer>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <div>
          <GridContainer>
            <GridItem style={{ height: "100%" }} xs={12} sm={12} md={6}>
              <Card className={myclasses.karttaCard}>
                <CardBody>
                  {geedata.length < 1 && (
                    <div>
                      Valintojesi perusteella kartan alueella ei ole seuroja,
                      jotka tarjoaisivat kiinnostuksesi kohteena olevia
                      harrastusmahdollisuuksia.
                    </div>
                  )}
                  {geedata.length == 999999991 && (
                    <div>
                      Valintojesi perusteella kartan alueella on 1 seura, joka
                      tarjoaa kiinnostuksesi kohteena olevia
                      harrastusmahdollisuuksia. Kartalla on esitetty tummemalla
                      värillä ne hallit joissa em. seurat toimivat
                    </div>
                  )}

                  {geedata.length > 9999999991 && (
                    <div>
                      Valintojesi perusteella kartan alueella on{" "}
                      {geedata.length} kpl seuroja, jotka tarjoavat
                      kiinnostuksesi kohteena olevia harrastusmahdollisuuksia.
                      Kartalla on esitetty tummemalla värillä ne hallit joissa
                      em. seurat toimivat
                    </div>
                  )}
                  <MapContext.Provider value={openlayersMap}>
                    <Kartta
                      viewChange={viewChange}
                      mapCenter={mapCenter}
                      onClick={karttaClick}
                      wmsSource={jaahalliSource}
                    />
                  </MapContext.Provider>
                </CardBody>
              </Card>
            </GridItem>
            {seuravisible > 0 && (
              <GridItem style={{ height: "100%" }} xs={12} sm={12} md={6}>
                <div
                  style={{
                    paddingTop: "10px",
                    minHeight: "332px",
                    maxHeight: "432px",
                  }}
                >
                  <SeuraInfo
                    seuraClose={seuraClose}
                    seuraImage={seuraImage}
                    seuraTieto={seuraTieto}
                    seuraFCdata={seuraFCdata}
                  ></SeuraInfo>
                </div>
              </GridItem>
            )}
            <Karuselli seuradata={geedata} onClick={karuselliClick} />
          </GridContainer>
        </div>
      </div>
      <Hidden xsDown>
        <i>
          <img
            className={classes.intoh}
            src={"img/intohimona_jaakiekko_2020.jpg"}
            alt={"Intohimona jääkiekko"}
          />
        </i>
        <Footer></Footer>
      </Hidden>
    </div>
  );
}

import React from "react";
// nodejs library that concatenates classes
//import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import { ListItem } from "@material-ui/core";
import { Link } from "react-router-dom";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
//import Card from "components/Card/Card.js";
import styles from "assets/jss/material-kit-react/views/laskeutumisSivu.js";

// Sections for this page
/*import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import WorkSection from "./Sections/WorkSection.js";
*/
const dashboardRoutes = [];
//https://www.facebook.com/pages/Suomen-J%C3%A4%C3%A4kiekkoliitto/794817883948254
const useStyles = makeStyles(styles);
//kuva: https://unsplash.com/s/photos/ice-rink
//https://www.youtube.com/watch?v=e8Ok89Hu-Bg
//https://www.youtube.com/watch?v=4HjbR_kOkvs
export default function LaskeutumisSivu(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        className={classes.headertext}
        color="primary"
        routes={dashboardRoutes}
        brand="EtsiSeura"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax
        image={
          require("assets/img/chris-liverani-5oZ9uVx7buc-unsplash.jpg").default
        }
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>
                Tervetuloa harrastamaan jääkiekkoa!
              </h1>
              <h4 className={classes.titleH4}>
                Tämän sivuston avulla voit hakea sinulle sopivimman seuran.
              </h4>
              <br />
              <Link to="/haku">
                <Button
                  className={classes.buttontext}
                  color="success"
                  size="lg"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-play" />
                  Siirry hakemaan
                </Button>
              </Link>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <Footer />
    </div>
  );
}

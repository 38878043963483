import React from "react";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import SeuraInfoFC from "./SeuraInfoFC.js";
import styles from "assets/jss/material-kit-react/views/touhuSivu.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);
export default function SeuraInfo(props) {
  //const { ...rest } = props;
  const classes = useStyles();
  const { seuraFCdata, seuraTieto, seuraClose, seuraImage } = props;
  console.log("aiddat :", seuraFCdata);
  console.log("aiddat seura :", seuraTieto);
  return (
    <Card className={classes.card}>
      <CardHeader className={classes.cardHeader}>{seuraTieto.name}</CardHeader>
      <CardBody>
        <div style={{ width: "20%" }}>
          <img src={seuraImage} alt={seuraTieto.name + "(Logo puuttuu)"} />
        </div>
        {seuraTieto.introduction}
        <div>
          <br></br>
          Kartalla oleva teemoitus kuvaa sitä millä alueilla seuran harrastajia
          sijaitsee
          <br></br>
          <br></br>
        </div>
        <div>
          <a
            href={
              "https://www.leijonat.fi/index.php/seurat?aid=" + seuraTieto.aid
            }
            rel="noreferrer"
            className={classes.block}
            target="_blank"
          >
            Tästä seuran omille sivuille
          </a>
          <br></br>
        </div>
        <div onClick={seuraClose}>
          <br></br>
          <span>
            <Button color="success" round>
              Sulje ja palaa seurakaruselliin
            </Button>
          </span>
        </div>
        <SeuraInfoFC
          className={classes.container}
          seuraFCdata={seuraFCdata}
        ></SeuraInfoFC>
        <div>
          <h5 className={classes.titleH4}>
            <a
              href={
                "https://www.leijonat.fi/index.php/seurat?aid=" + seuraTieto.aid
              }
              rel="noreferrer"
              className={classes.block}
              target="_blank"
            >
              Tästä seuran omille sivuille
            </a>
          </h5>
        </div>
        <div onClick={seuraClose}>
          <span>
            <Button color="success" round>
              Sulje ja palaa seurakaruselliin
            </Button>
          </span>
        </div>
      </CardBody>
    </Card>
  );
}
SeuraInfo.propTypes = {
  seuraImage: PropTypes.string,
  seuraTieto: PropTypes.object,
  seuraFCdata: PropTypes.array,
  seuraClose: PropTypes.func,
};

// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import fetch from "cross-fetch";
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function NavInput(props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  //  const [value, setValue] = React.useState(null);
  const loading = open && options.length === 0;

  //"https://country.register.gov.uk/records.json?page-size=5000"
  var urli = props.urli;
  React.useEffect(() => {
    let active = true;
    console.log("opt len: " + options.length);
    //console.log("options: ", options);
    if (!loading) {
      return undefined;
    }
    if (options.length > 0) {
      return undefined;
    }
    (async () => {
      //const response = await fetch({ urli });
      const response = await fetch(urli);
      await sleep(1); // For demo purposes.
      //console.log("response obj: o%", response);
      const countries = await response.json();
      //const countries = await response;
      //console.log("response.data: " + response.data);
      //console.log("countries: " + countries);
      //console.log("countries[0]: o% ", countries[0]["posti_alue"]);
      //console.log("countries[0]: o% ", countries[0].posti_alue);
      ////console.log("sadasd: " + Object.keys(countries));
      if (active) {
        setOptions(Object.keys(countries).map((key) => countries[key]));
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  /*React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  */
  return (
    <Autocomplete
      onChange={(event, newValue) => {
        //setValue(newValue);
        //console.log("newvalue: o% ", newValue);
        props.onChange(newValue);
      }}
      id="NavInput"
      style={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
NavInput.propTypes = {
  urli: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

import {
  container,
  title,
  defaultFont,
  titleH4,
  card,
  cardHeader,
} from "assets/jss/material-kit-react.js";

const landingPageStyle = {
  intoh: {
    xxmaxHeight: "166px",
    width: "100%",
    maxWidth: "1105px",
    xxdisplay: "block",
  },
  container: {
    ...defaultFont,
    zIndex: "12",
    color: "#FFFFFF",
    ...container,
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none",
  },
  titleH4: {
    ...titleH4,
    display: "inline-block",
    position: "relative",
    marginTop: "20px",
    minHeight: "12px",
    color: "#FFFFFF",
    textDecoration: "none",
  },
  titleH4_FC: {
    ...titleH4,
    display: "inline-block",
    position: "relative",
    marginTop: "20px",
    minHeight: "12px",
    color: "#FFFFFF",
    textDecoration: "none",
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  card: {
    ...card,
    ...container,
    marginTop: "0px",
    maxHeight: "514px",
    overflow: "scroll",
  },
  cardHeader: {
    ...cardHeader,
    marginTop: "0px",
  },
  overFlow: {
    overflow: "auto",
  },
};

export default landingPageStyle;

import React from "react";
// react component for creating beautiful carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "react-slick";
// material-ui components
// @material-ui/icons
//import LocationOn from "@material-ui/icons/LocationOn";
// core components
//import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
//import CardHeader from "components/Card/CardHeader.js";
import Seurakuva from "components/Seurakuva/Seurakuva.js";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

/*const seura1 = ["10115406", "Viikingit"];
const seura2 = ["10115448", "Viikingit"];
const seura3 = ["10115451", "Viikingit"];*/
const myUseStyles = makeStyles((theme) => ({
  karttaCard: {
    marginTop: 1,
    marginBottom: 1,
    minHeight: 350,
    minWidth: 350,
    maxWidth: 750,
    //padding: theme.spacing(2),
    paddingLeft: 1,
    paddingRight: 1,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
export default function Karuselli(props) {
  const myclasses = myUseStyles();
  function slickClick(id) {
    /// gonna do
    // http://www.leijonat.fi/index.php/seurat?aid=10110977
    props.onClick(id);
  }
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
  };
  //var jepus = "tähän seuraid";
  //console.log("karusellissa len: " + props.seuradata.length);
  //console.log("karusellissa: o%", props.seuradata[0]);
  //var apuva = [];
  var apuva = props.seuradata;
  //apuva.map((prop, key) => {
  /*  props.seuradata.map((prop, key) => {
    console.log("zip:" + prop.zip);
    console.log("key:" + key);
  });*/
  if (props.seuradata.length == 0) {
    return (
      <GridItem xs={12} sm={12} md={6}>
        <Card className={myclasses.karttaCard}>
          <CardBody>
            <div>
              Valitse harrastusmahdollisuuksia, joista olet kiinnostunut niin
              saat tähän tietoa niitä tarjoavista seuroista. Klikkaamalla seuran
              logoa saat lisätietoja kyseisestä seurasta.
            </div>
          </CardBody>
        </Card>
      </GridItem>
    );
  } else {
    //console.log("karusellissa: " + props.seuradata[0]["aid"]);
    //console.log("karusellissax: " + props.seuradata[0]["name"]);
    return (
      <GridItem xs={12} sm={12} md={6}>
        <Card className={myclasses.karttaCard}>
          <CardBody style={{ height: "100%" }}>
            <Carousel {...settings}>
              {apuva.map(({ name, aid }, index) => (
                <Seurakuva
                  key={index * 100}
                  index={index}
                  seuranimi={name}
                  seuraid={aid}
                  slickClick={slickClick}
                />
              ))}
            </Carousel>
          </CardBody>
        </Card>
      </GridItem>
    );
  }
}
Karuselli.propTypes = {
  seuradata: PropTypes.array,
  onClick: PropTypes.func,
};
